<template>
  <div style="height: 100%;background: #f7f8fa;">
    <van-nav-bar :title="title" :z-index="999" :fixed="true" left-arrow @click-left="back" />
    <div style="padding:46px 0px;">
      <van-cell-group>
        <van-field v-model="userInfo.userName" v-if="cid == 0" placeholder="填写真实姓名" />
        <van-field
          v-model="userInfo.phone"
          left-icon="phone"
          v-if="cid == 1"
          center
          clearable
          placeholder="请输入手机号码"
        >
          <!-- <template #button>
            <van-button size="small" type="danger" @click="fasong" :disabled="sendMsgDisabled">
              <span v-if="!sendMsgDisabled">获取验证码</span>
              <span v-if="sendMsgDisabled">{{codeTime+'秒后获取'}}</span>
            </van-button>
          </template>-->
        </van-field>
        <!-- <van-icon name="goods-collect-o" /> -->
        <!-- <van-field
          v-model="userInfo.vcode"
          left-icon="lock"
          v-if="cid == 1"
          placeholder="请输入短信验证码"
        />-->
      </van-cell-group>
      <van-field v-model="userInfo.realName" v-if="cid == 2" placeholder="填写昵称" />
      <div class="baocun">
        <van-button round type="danger" block @click="baocun">保存</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      loading: false,
      shangjias: JSON.parse(sessionStorage.getItem("shangjia")),
      finished: true,
      sendMsgDisabled: false,
      codeTime: 59, // 发送验证码倒计时
      value: "",
      title: "",
      list: []
    };
  },

  methods: {
    tianjia() {
      this.$router.push({
        path: "model_list"
        // query: {
        //   status: status
        // }
      });
    },
    //保存个人信息
    baocun() {
      if (this.cid == 0) {
        console.log("45654654");
        if (this.userInfo.userName == null) {
          this.$toast("输入不能为空!");
          return;
        }
        if (this.userInfo.userName == "") {
          this.$toast("输入不能为空!");
          return;
        }
        const data = {
          userName: this.userInfo.userName,
          gid: this.shangjias.id
        };
        this.cbaocun(data);
      }
      if (this.cid == 1) {
        if (this.userInfo.phone == null) {
          this.$toast("请输入手机号码");
          return;
        }
        if (this.userInfo.phone == "") {
          this.$toast("请输入手机号码");
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.userInfo.phone)) {
          this.$toast("手机号格式错误");
          return;
        }
        // if (this.userInfo.vcode == null) {
        //   this.$toast("请输入验证码");
        //   return;
        // }

        // if (this.userInfo.vcode == "") {
        //   this.$toast("请输入验证码");
        //   return;
        // }
        const data = {
          phone: this.userInfo.phone,
          // vcode: this.userInfo.vcode,
          gid: this.shangjias.id
        };
        this.cbaocun(data);
      }
      if (this.cid == 2) {
        if (this.userInfo.realName == null) {
          this.$toast("输入不能为空!");
          return;
        }
        if (this.userInfo.realName == "") {
          this.$toast("输入不能为空!");
          return;
        }
        const data = {
          realName: this.userInfo.realName,
          gid: this.shangjias.id
        };
        this.cbaocun(data);
      }
    },
    cbaocun(data) {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      userModel
        .info(data)
        .then(e => {
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          // loading.clear();
          this.$toast("修改成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        })
        .catch(() => loading.clear());
    },
    //发送验证码
    fasong() {
      if (this.userInfo.phone == null) {
        this.$toast("请输入手机号码");
        return;
      }
      if (this.userInfo.phone == "") {
        this.$toast("请输入手机号码");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.userInfo.phone)) {
        this.$toast("手机号格式错误");
        return;
      }
      const data = {
        phone: this.userInfo.phone
      };
      var _this = this;

      userModel
        .msgCode(data)
        .then(e => {
          if (e.code == 200) {
            _this.$toast("验证码已发送到您手机请查收");
            _this.sendMsgDisabled = true;
            let codeInterval = window.setInterval(function() {
              if (_this.codeTime-- <= 0) {
                _this.codeTime = 59;
                _this.sendMsgDisabled = false;
                window.clearInterval(codeInterval);
              }
            }, 1000);
          }
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.cid = this.$route.query.cid;
    if (this.cid == 0) {
      this.title = "修改真实姓名";
    } else if (this.cid == 1) {
      if (this.$route.query.titel) {
        this.title = this.$route.query.titel;
      } else {
        this.title = "修改手机号";
      }
    } else if (this.cid == 2) {
      this.title = "修改昵称";
    }
    this.active = this.$route.query.cid;
    // this.onLoad()
  }
};
</script>

<style lang="less" scoped>
.toux {
  width: 40px;
  height: 40px;

  vertical-align: middle;
  border-radius: 50%;
}
.baocun {
  margin: 10px;
}
</style>
